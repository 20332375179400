import React from "react";
import GoogleIcon from "../../assets/icons/google.svg";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import app from "../../../config/app";

const GoogleComponent = () => {
  const navigate = useNavigate();
    const auth = getAuth(app);
  auth.languageCode = "es";
  const provider = new GoogleAuthProvider();

  const handleAuth = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user);
        console.log(token);
        navigate("/home");
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <button
      type="button"
      className="row m-auto app-login-button-google"
      onClick={handleAuth}
    >
      <div className="col-1">
        <img src={GoogleIcon} alt="google-logo" width="16px" height="16px" />
      </div>
      <div className="col-11">Continuar con Google</div>
    </button>
  );
};

export default GoogleComponent;
