import React from "react";
import app from "../../../config/app";
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import FacebockIcon from "../../assets/icons/facebookv2.svg";
import { useNavigate } from "react-router-dom";

const FacebookComponent = () => {
  const navigate = useNavigate();
  const auth = getAuth(app);
  const provider = new FacebookAuthProvider();

  const handleAuth = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        console.log(user);
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log(accessToken);
        navigate("/home")
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });
  };

  return (
    <button
      type="button"
      className="row m-auto app-login-button-facebook"
      onClick={handleAuth}
    >
      <div className="col-1">
        <img src={FacebockIcon} alt="google-logo" width="16px" height="16px" />
      </div>
      <div className="col-11">Continuar con Facebook</div>
    </button>
  );
};

export default FacebookComponent;
