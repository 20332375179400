import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import CardsComponent from "../../components/wallet/cards/CardsComponent";
import HistorialComponent from "../../components/wallet/historial/HistorialComponent";
import "./Wallet.css";

const Wallet = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-wallet">
      <div className="row app-wallet-header">
        <div className="col-2 col-md-2" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div className="col-10 col-md-10">WALLET</div>
      </div>
      <div className="app-wallet-container">
        <div className="app-wallet-cards">
          <CardsComponent />
        </div>
        <div className="app-wallet-information">
          <span>Por cada compra recibes</span>
          <span>50 puntos en tu wallet</span>
        </div>
        <div className="app-wallet-historial">
          <div className="app-historial-title">Historial de transacciones</div>
          <HistorialComponent />
        </div>
      </div>
    </div>
  );
};

export default Wallet;
