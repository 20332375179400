import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";

const ForgetPasswordComponent = () => {
  const formik = useFormik({
    initialValues: { codigo1: "", codigo2: "", codigo3: "", codigo4: "" },
    validationSchema: yup.object({
      codigo1: yup.number().min(1).max(1).required(),
      codigo2: yup.number().min(1).max(1).required(),
      codigo3: yup.number().min(1).max(1).required(),
      codigo4: yup.number().min(1).max(1).required(),
    }),
    onSubmit: (values) => {},
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="app-forget-label m-auto">
        <span>Enviamos un PIN de recuperación
        de tu contraseña a tu correo registrado.</span>
      </div>
      <div className="app-forget-label">
        <span>micorreo@gmail.com</span>
      </div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          id="codigo1"
          name="codigo1"
          value={formik.values.codigo1}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo2"
          name="codigo2"
          value={formik.values.codigo2}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo3"
          name="codigo3"
          value={formik.values.codigo3}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
        <input
          type="text"
          className="form-control"
          id="codigo4"
          name="codigo4"
          value={formik.values.codigo4}
          onChange={formik.handleChange}
          aria-label="Example text with two button addons"
        />
      </div>
      <div className="app-forget-info">
        <span>¿No recibiste el PIN?</span>
      </div>
      <div className="row">
        <div className="col-12">
          <button type="submit" className="app-forget-button">
            Reenviar PIN
          </button>
        </div>
      </div>
    </form>
  );
};

export default ForgetPasswordComponent;
