import React, { useEffect, useState } from "react";
import db from "../../database/db";

const NewsComponent = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    setNews(db.news);

    return () => {};
  }, []);

  return (
    <div className="app-news-list">
      {news.map((item, index) => (
        <div key={index} className="app-news-item">
          <img
            src={item.imagen}
            alt={`news ${index}`}
            width="100%"
            height="auto"
          />
          <span className="app-news-item-description">{item.description}</span>
        </div>
      ))}
    </div>
  );
};

export default NewsComponent;
