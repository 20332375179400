import React, { useEffect, useState } from "react";
import OnboardingHeader from "../../assets/images/onboarding_header.webp";
import db from "../../database/db";
import "./onboarding.css";
import "animate.css";

const OnboardingComponent = (props) => {
  const [carousel, setCaurousel] = useState([]);
  const [current, setCurrent] = useState({});

  useEffect(() => {
    setCaurousel(db.carousel);
    setCurrent(db.carousel[1]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrent = (value) => {
    setCurrent(value);
  };

  return (
    <div className="app-carousel">
      <div className="app-carousel-header">
        <img src={OnboardingHeader} alt="header" width="100%" height="auto" />
      </div>
      <div
        className="app-carousel-container"
        style={{
          backgroundImage: `url(${current?.background})`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="app-carousel-shadow">
          <div className="app-carousel-body">
            <div
              className="app-carousel-title"
              style={{ paddingBottom: current?.id === 2 && "20%" }}
            >
              {current?.title}
            </div>
            <div className="app-carousel-subtitle">{current?.subtitle}</div>
          </div>
        </div>
      </div>
      <div className="app-carousel-indicators">
        {carousel.map((item, index) => (
          <button
            key={index}
            type="button"
            className={`${current?.id === item.id ? "active " : ""}`}
            onClick={() => handleCurrent(item)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default OnboardingComponent;
