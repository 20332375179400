import React, { useEffect, useState } from "react";
import CheckCircle from "../../../assets/icons/check-circle.svg";
import db from "../../../database/db";
import "./Benefits.css";

const BenefitsComponent = () => {
  const [show, setShow] = useState(false);
  const [leves, setLeves] = useState([]);

  useEffect(() => {
    setLeves(db.leves);

    return () => {};
  }, []);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="app-benefits">
      <div className="app-benefits-header">
        <div className="col-10 col-md-10">Beneficios Tucanê</div>
        <div className="col-2 col-md-2">
          <button onClick={handleShow}>
            {!show ? (
              <i className="bi bi-chevron-down fw-bold"></i>
            ) : (
              <i className="bi bi-chevron-up fw-bold"></i>
            )}
          </button>
        </div>
      </div>
      {show && (
        <div className="app-benefits-carousel">
          {leves.map((item, index) => (
            <div key={index} className="col">
              <div className="app-benefits-level">
                <div className="app-benefits-level-header">
                  <span className="app-benefits-title">{item.title}</span>
                  <span className="app-benefits-status">
                    {!item.status ? (
                      <span></span>
                    ) : (
                      <img
                        src={CheckCircle}
                        alt="check"
                        width="16px"
                        height="16px"
                      />
                    )}
                  </span>
                </div>
                <div className="app-benefits-bar">
                  <span className={`${item.status && "fill"}`} style={{width: item.percentage}}></span>
                </div>
                <div className="row d-flex">
                  <ul className="app-benefists-list">
                    {item.benefits.map((item, index) => (
                      <li key={index}>{item.title}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BenefitsComponent;
