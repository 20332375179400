import React, { useEffect, useState } from "react";
import Qr from "../../../assets/icons/qr.svg";
import RowBack from "../../../assets/icons/row-back.svg";
import QRCode from "react-qr-code";
import db from "../../../database/db";
import "./Cards.css";
import Card from "../../home/card/Card";

const CardsComponent = () => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(db.cards);

    return () => {};
  }, []);

  return cards.map((item, index) => <Card key={index} {...item} />);
};

export default CardsComponent;
