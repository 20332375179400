import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import PromotionsComponent from "../../components/promotions/PromotionsComponent";
import "./Promotions.css";

const Promotions = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-promotions">
      <div className="row app-promotions-header">
        <div className="col-2 col-md-2" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div className="col-10 col-md-10">PROMOCIONES</div>
      </div>
      <div className="app-promotions-container">
        <PromotionsComponent />
      </div>
    </div>
  );
};

export default Promotions;
