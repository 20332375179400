import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Account from "../../../assets/images/account.svg";
import Pencil from "../../../assets/icons/edit.svg";
import ActionsContext from "../../../context/ActionsContext";
import ChangePassword from "./ChangePassword";
const ProfileComponent = () => {
  const [photo, setPhoto] = useState(null);
  const { dialog } = useContext(ActionsContext);

  console.log(dialog);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      correo: "",
      genero: "",
      estado_id: "",
      ciudad_id: "",
      direccion: "",
      numero_exterior: "",
      numero_interior: "",
      colonia: "",
      codigo_postal: "",
    },
    validationSchema: {},
    onSubmit: () => {},
  });

  const handleChangePassword = () => {
    dialog.handleDialog(<ChangePassword />);
  };

  const handlePhoto = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpg,image/jpeg,image/png";
    input.click();
    input.addEventListener("change", (e) => {
      const photo = e.target.files[0];
      if (photo) {
        setPhoto(URL.createObjectURL(photo));
      }
    });
  };

  return (
    <div>
      <div className="app-profile-account">
        <img src={photo ? photo : Account} alt="" width="60px" height="60px" />
        <button className="app-profile-account-icon" onClick={handlePhoto}>
          <img src={Pencil} alt="Edit" width="16px" height="16px"></img>
        </button>
      </div>
      <form onSubmit={formik.handleSubmit} className="app-profile-form">
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="nombre">
            <input
              className="app-form-control"
              type="text"
              name="nombre"
              id="nombre"
              placeholder="Nombre Completo"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Nombre Completo</span>
          </label>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="nombre">
            <input
              className="app-form-control"
              type="email"
              name="correo"
              id="correo"
              placeholder="Correo electrónico"
              value={formik.values.correo}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Correo electrónico</span>
          </label>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="genero">
            <select
              className="app-form-select"
              id="genero"
              name="genero"
              placeholder="seleccione una opción"
            >
              <option value="0">seleccione una opción</option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
            </select>
            <span>Género</span>
          </label>
        </div>
        <div className="app-form-divider">
          <span></span>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="estado_id">
            <select
              className="app-form-select"
              id="estado_id"
              name="estado_id"
              placeholder="seleccione una opción"
            >
              <option value="0">seleccione una opción</option>
              <option value="1">Jalisco</option>
            </select>
            <span>Estado</span>
          </label>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="ciudad">
            <input
              className="app-form-control"
              type="text"
              name="ciudad"
              id="ciudad"
              placeholder="Ciudad"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Ciudad</span>
          </label>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="municipio">
            <input
              className="app-form-control"
              type="text"
              name="municipio"
              id="municipio"
              placeholder="Población o Municipio"
              value={formik.values.correo}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Población o Municipio</span>
          </label>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="direccion">
            <input
              className="app-form-control"
              type="text"
              name="direccion"
              id="direccion"
              placeholder="Dirección"
              value={formik.values.correo}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Dirección</span>
          </label>
        </div>
        <div className="app-form-group">
          <div className="app-form-floating">
            <label className="app-form-label" htmlFor="numero_exterior">
              <input
                className="app-form-control"
                type="text"
                name="numero_exterior"
                id="numero_exterior"
                placeholder="No. Exterior"
                value={formik.values.correo}
                onChange={formik.handleChange}
                autoComplete="off"
              />
              <span>No. Exterior</span>
            </label>
          </div>
          <div className="app-form-floating">
            <label className="app-form-label" htmlFor="numero_interior">
              <input
                className="app-form-control"
                type="text"
                name="numero_interior"
                id="numero_interior"
                placeholder="No. Interior (Opc)"
                value={formik.values.correo}
                onChange={formik.handleChange}
                autoComplete="off"
              />
              <span>No. Interior (Opc)</span>
            </label>
          </div>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="colonia">
            <input
              className="app-form-control"
              type="text"
              name="colonia"
              id="colonia"
              placeholder="Colonia"
              value={formik.values.correo}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Colonia</span>
          </label>
        </div>
        <div className="app-form-floating">
          <label className="app-form-label" htmlFor="codigo_postal">
            <input
              className="app-form-control"
              type="text"
              name="codigo_postal"
              id="codigo_postal"
              placeholder="Código Postal"
              value={formik.values.correo}
              onChange={formik.handleChange}
              autoComplete="off"
            />
            <span>Código Postal</span>
          </label>
        </div>
        <div className="app-form-divider">
          <span></span>
        </div>
        <div className="app-form-btn-outline">
          <button type="button" onClick={handleChangePassword}>
            Cambiar Contraseña
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileComponent;
