import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import db from "../../../database/db";
import "./Card.css";
import Card from "./Card";

const CardsComponent = (props) => {
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(db.cards);

    return () => {};
  }, []);

  const handleGoToWallet = () => {
    navigate("wallet");
  };

  return cards.map((item, index) => (
    <Card key={index} {...item} handleGoToWallet={handleGoToWallet} isHidden={true}/>
  ));
};

export default CardsComponent;
