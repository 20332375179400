import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import NewsComponent from "../../components/news/NewsComponent";
import "./News.css";

const News = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-news">
      <div className="row app-news-header">
        <div className="col-2 col-md-2" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div className="col-10 col-md-10">NOTICIAS</div>
      </div>
      <div className="app-news-container">
        <NewsComponent />
      </div>
    </div>
  );
};

export default News;
