import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import EventsComponent from "../../components/events/EventsComponent";
import "./Events.css";

const Events = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-events">
      <div className="row app-events-header">
        <div className="col-2 col-md-2" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div className="col-10 col-md-10">PRÓXIMOS EVENTOS</div>
      </div>
      <div className="app-events-container">
        <EventsComponent/>
      </div>
    </div>
  );
};

export default Events;
