import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Carousel.css";
import "animate.css";
import db from "../../../database/db";


const CarouselComponent = (props) => {
  const [carousel, setCaurousel] = useState([]);
  const [current, setCurrent] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    setCaurousel(db.home_carousel);
    setCurrent(db.home_carousel[0]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrent = (value) => {
    setCurrent(value);
  };

  const handleGoTo = (route) => {
    navigate(route);
  };

  return (
    <div className="app-carousel">
      <div
        className="app-carousel-container"
        style={{
          backgroundImage: `url(${current?.background})`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
        }}
        onClick={() => handleGoTo(current?.url)}
      >
        <div className="app-carousel-body">
          <div className="app-carousel-title">{current?.title}</div>
        </div>
      </div>
      <div className="app-carousel-indicators">
        {carousel.map((item, index) => (
          <button
            key={index}
            type="button"
            className={`${current?.id === item.id ? "active " : ""}`}
            onClick={() => handleCurrent(item)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default CarouselComponent;
