/*----------------------achievements----------------------------*/
import Badge1 from "../assets/images/achievements/badge1.webp";
import Badge2 from "../assets/images/achievements/badge2.webp";
import Badge3 from "../assets/images/achievements/badge3.webp";
import Badge4 from "../assets/images/achievements/badge4.webp";
import Badge5 from "../assets/images/achievements/badge5.webp";
import Badge6 from "../assets/images/achievements/badge6.webp";
import Badge7 from "../assets/images/achievements/badge7.webp";
import Badge8 from "../assets/images/achievements/badge8.webp";
import Badge9 from "../assets/images/achievements/badge9.webp";
import Badge10 from "../assets/images/achievements/badge10.webp";
import Badge11 from "../assets/images/achievements/badge11.webp";
import Badge12 from "../assets/images/achievements/badge12.webp";
/*--------------------------Carousel----------------------------*/
import Onboarding1 from "../assets/images/onboarding/onboarding1.webp";
import Onboarding2 from "../assets/images/onboarding/onboarding2.webp";
import Onboarding3 from "../assets/images/onboarding/onboarding3.webp";
import Onboarding4 from "../assets/images/onboarding/onboarding4.webp";
/*--------------------------Cards-------------------------------*/
import Card from "../assets/images/home/cards/card-image.webp";
/*--------------------------Home Carousel-----------------------*/
import Carousel1 from "../assets/images/home/carousel/carousel1.webp";
import Carousel2 from "../assets/images/home/carousel/carousel2.webp";
import Carousel3 from "../assets/images/home/carousel/carousel3.webp";
/*--------------------------Events------------------------------*/
import Event1 from "../assets/images/home/events/imagen1.webp";
import Event2 from "../assets/images/home/events/imagen2.webp";
import Event3 from "../assets/images/home/events/imagen3.webp";
/*--------------------------Promotions--------------------------*/
import Promotion1 from "../assets/images/home/promotions/imagen1.webp";
import Promotion2 from "../assets/images/home/promotions/imagen2.webp";
import Promotion3 from "../assets/images/home/promotions/imagen3.webp";
/*--------------------------News--------------------------------*/
import New1 from "../assets/images/home/news/imagen1.webp";
import New2 from "../assets/images/home/news/imagen2.webp";
import New3 from "../assets/images/home/news/imagen3.webp";
/*--------------------------Store-------------------------------*/
import Store1 from "../assets/images/home/store/mens_category.webp";
import Store2 from "../assets/images/home/store/womans_category.webp";
import Store3 from "../assets/images/home/store/childs_category.webp";
import Store4 from "../assets/images/home/store/specials_category.webp";
import Store5 from "../assets/images/home/store/sales_category.webp";
/*--------------------------Cards-Gift---------------------------*/
import Card1 from "../assets/images/giftcards/card1.svg";
import Card2 from "../assets/images/giftcards/card2.svg";
import Card3 from "../assets/images/giftcards/card3.svg";

const db = {
  carousel: [
    {
      id: 1,
      title: "te damos la bienvenida al club tucanê",
      subtitle: "Regístrate para obtener fabulosas recompensas y experiencias.",
      background: Onboarding1,
    },
    {
      id: 2,
      title: "cada compra te acerca a una nueva experiencia",
      subtitle:
        "Al comprar y realizar acciones, sube niveles obteniendo grandes beneficios.",
      background: Onboarding2,
    },
    {
      id: 3,
      title: "tu historial de compras en un solo clic",
      subtitle: "Tan rápido como comprar y ganar.",
      background: Onboarding3,
    },
    {
      id: 4,
      title: "comparte tu estilo",
      subtitle: "Hazle saber a todos qué es para ti Tucanê.",
      background: Onboarding4,
    },
  ],
  home_carousel: [
    {
      id: 1,
      title: "EVENTOS PRÓXIMOS",
      background: Carousel1,
      url: "events",
    },
    {
      id: 2,
      title: "PROMOCIONES",
      background: Carousel2,
      url: "promotions",
    },
    {
      id: 3,
      title: "NOTICIAS",
      background: Carousel3,
      url: "news",
    },
  ],
  categories: [
    {
      id: 1,
      category: "Hombre",
      color: "#000000",
      image: Store1,
      url: "",
    },
    {
      id: 2,
      category: "Mujer",
      color: "#000000",
      image: Store2,
      url: "",
    },
    {
      id: 3,
      category: "Infantil",
      color: "#000000",
      image: Store3,
      url: "",
    },
    {
      id: 4,
      category: "Especiales",
      color: "#000000",
      image: Store4,
      url: "",
    },
    {
      id: 5,
      category: "Rebajas",
      color: "#CA1212",
      image: Store5,
      url: "",
    },
  ],
  leves: [
    {
      id: 1,
      title: "NIVEL 1",
      status: true,
      percentage: "100%",
      benefits: [
        { id: 1, title: "1 a 2 compras (en el año)." },
        {
          id: 2,
          title: "Por cada compra recibes el 2% de cada una en puntos Tucanê.",
        },
        { id: 3, title: "Entre más compras realices mayores beneficios." },
      ],
    },
    {
      id: 2,
      title: "NIVEL 2",
      status: true,
      percentage: "100%",
      benefits: [
        { id: 1, title: "1 a 2 compras (en el año)." },
        {
          id: 2,
          title: "Por cada compra recibes el 2% de cada una en puntos Tucanê.",
        },
        { id: 3, title: "Entre más compras realices mayores beneficios." },
      ],
    },
    {
      id: 3,
      title: "NIVEL 3",
      status: false,
      percentage: "60%",
      benefits: [
        { id: 1, title: "1 a 2 compras (en el año)." },
        {
          id: 2,
          title: "Por cada compra recibes el 2% de cada una en puntos Tucanê.",
        },
        { id: 3, title: "Entre más compras realices mayores beneficios." },
      ],
    },
  ],
  cards: [
    {
      id: 1,
      count: "30",
      title: "COMPRAS",
      background:
        "linear-gradient(180deg, rgba(22, 21, 19, 0) 0%, #161513 100%)",
      imagen: Card,
      code: "XTRYU",
      footer: "",
    },
  ],
  transactions: [
    {
      id: 1,
      items: [
        {
          id: 1,
          name: "Blusa Tucanê",
          size: "M",
          type: "add",
          points: "10",
        },
        {
          id: 1,
          name: "Blusa Tucanê",
          size: "M",
          type: "del",
          points: "5",
        },
        {
          id: 1,
          name: "Blusa Tucanê",
          size: "M",
          type: "add",
          points: "2",
        },
        {
          id: 1,
          name: "Blusa Tucanê",
          size: "M",
          type: "add",
          points: "5",
        },
      ],
      date: "20220510",
    },
    {
      id: 2,
      items: [
        {
          id: 1,
          name: "Blusa Tucanê",
          size: "M",
          type: "add",
          points: "10",
        },
      ],
      date: "20220511",
    },
  ],
  events: [
    {
      id: 1,
      title: "",
      subtitle: "",
      discount: "",
      type: "",
      description: "POOL PARTY 2022",
      imagen: Event1,
    },
    {
      id: 2,
      title: "",
      subtitle: "",
      discount: "",
      type: "",
      description: "PASARELA COLECCIÓN OTOÑO",
      imagen: Event2,
    },
    {
      id: 3,
      title: "",
      subtitle: "",
      discount: "",
      type: "",
      description: "",
      imagen: Event3,
    },
  ],
  promotions: [
    {
      id: 1,
      title: "Outlet",
      subtitle: "",
      discount: "",
      type: "",
      description: "",
      imagen: Promotion1,
    },
    {
      id: 2,
      title: "",
      subtitle: "COMPRAS EN LÍNEA",
      discount: "30%",
      type: "",
      description: "",
      imagen: Promotion2,
    },
    {
      id: 3,
      title: "",
      subtitle: "ACCESORIOS",
      discount: "50%",
      type: "",
      description: "",
      imagen: Promotion3,
    },
  ],
  news: [
    {
      id: 1,
      title: "",
      subtitle: "",
      discount: "",
      type: "",
      description: "TORNEO DE POLO",
      imagen: New1,
    },
    {
      id: 2,
      title: "",
      subtitle: "",
      discount: "",
      type: "",
      description: "INAUGURACIÓN PUNTO SUR",
      imagen: New2,
    },
    {
      id: 3,
      title: "",
      subtitle: "",
      discount: "",
      type: "",
      description: "",
      imagen: New3,
    },
  ],
  gifcards: [
    { id: 1, imagen: Card1 },
    { id: 2, imagen: Card2 },
    { id: 3, imagen: Card3 },
  ],
  achievements: [
    { id: 1, name: "Tucanê\nLover", imagen: Badge1 },
    { id: 2, name: "Reviewer", imagen: Badge2 },
    { id: 3, name: "Commenter", imagen: Badge3 },
    { id: 4, name: "Reward\nHunter", imagen: Badge4 },
    { id: 5, name: "Frequent\nVisitor", imagen: Badge5 },
    { id: 6, name: "Trender", imagen: Badge6 },
    { id: 7, name: "Spender", imagen: Badge7 },
    { id: 8, name: "Spender\nCompulsive", imagen: Badge8 },
    { id: 9, name: "Spenderholic", imagen: Badge9 },
    { id: 10, name: "The\nSocial", imagen: Badge10 },
    { id: 11, name: "Influencer", imagen: Badge11 },
    { id: 12, name: "Ambassador", imagen: Badge12 },
  ],

};

export default db;
