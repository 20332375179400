import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import HistorialComponent from "../../components/wallet/historial/HistorialComponent";
import "./Shopping.css";

const Shopping = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-shopping">
      <div className="app-shopping-header">
        <span className="app-shopping-header-icon" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </span>
        <span className="app-shopping-header-label">MIS COMPRAS</span>
      </div>
      <div className="app-shopping-container">
        <HistorialComponent />
      </div>
    </div>
  );
};

export default Shopping;
