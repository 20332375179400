import axios from "axios";
import { search } from "./models/search";

const url = process.env.REACT_APP_ODOO_URL;
const db = process.env.REACT_APP_ODOO_DB;

export async function postAuthenticate(data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `http://localhost:3001/authenticate`,
    headers: {
      "Content-Type": "application/xml",
    },
    data: JSON.stringify(data),
  };

  return axios.request(config);
}

export async function postSearchProducts(data) {
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${url}/xmlrpc/2/object`,
    headers: {
      "Content-Type": "application/xml"
    },
    mode: 'no-cors',
    data: search({ db, model: "product.template", ...data }),
  };

  return axios.request(config);
}
