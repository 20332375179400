import React, { useEffect, useState } from "react";
import db from "../../../database/db";
import "./Store.css";

const StoreComponent = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(db.categories);

    return () => {};
  }, []);

  return (
    <div className="app-store-carousel">
      {categories.map((item, index) => (
        <div key={index} className="col">
          <span className="app-store-title" style={{ color: item.color }}>
            {item.category}
          </span>
          <div className="app-store-category">
            <img
              className="app-store-imagen"
              src={item.image}
              alt={item.category}
              width="auto"
              height="auto"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default StoreComponent;
