import React, { useEffect, useState } from "react";
import db from "../../database/db";

const EventsComponent = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(db.events);

    return () => {};
  }, []);

  return (
    <div className="app-events-list">
      {events.map((item, index) => (
        <div key={index} className="app-events-item">
          <img
            src={item.imagen}
            alt={`events ${index}`}
            width="100%"
            height="auto"
          />
          <span className="app-events-item-description">{item.description}</span>
        </div>
      ))}
    </div>
  );
};

export default EventsComponent;
