import React from "react";
import { NavLink } from "react-router-dom";
import AchievementsOutline from "../../assets/icons/achievements-outline.svg";
import Achievements from "../../assets/icons/achievements.svg";
import MainOutline from "../../assets/icons/main-outline.svg";
import Main from "../../assets/icons/main.svg";
import ScanOutline from "../../assets/icons/scan-outline.svg";
import "./Footer.css";

const FooterComponent = () => {
  return (
    <div className="app-footer-container">
      <div className="app-footer-icon">
        <NavLink to="/achievements" className="nav-link">
          <img
            className="outline"
            src={AchievementsOutline}
            alt="achievements-outline"
            width="16px"
            height="16px"
          />
          <img
            className="fill"
            src={Achievements}
            alt="achievements"
            width="16px"
            height="16px"
          />
          <span>Logros</span>
        </NavLink>
      </div>
      <div className="app-footer-icon">
        <NavLink to="/home" className="nav-link">
          <img
            className="outline"
            src={MainOutline}
            alt=""
            width="16px"
            height="16px"
          />
          <img className="fill" src={Main} alt="" width="16px" height="16px" />
          <span>inicio</span>
        </NavLink>
      </div>
      <div className="app-footer-icon">
        <NavLink to="/home" className="nav-link">
          <img src={ScanOutline} alt="" width="16px" height="16px" />
          <span>Escanear</span>
        </NavLink>
      </div>
    </div>
  );
};

export default FooterComponent;
