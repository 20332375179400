import React, { useState } from "react";
import QRCode from "react-qr-code";
import IconWhatsApp from "../../../assets/icons/whatsapp.svg";
import IconMesseger from "../../../assets/icons/messeger.svg";
import IconGmail from "../../../assets/icons/gmail.svg";
import "./Share.css";

const ShareComponent = () => {
  const [show, setShow] = useState(false);
  const [code, setCode] = useState("TUI0989");

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="app-share">
      <div className="app-share-header">
        <div className="col-10 col-md-10">Comparte y gana</div>
        <div className="col-2 col-md-2">
          <button onClick={handleShow}>
            {!show ? (
              <i className="bi bi-chevron-down fw-bold"></i>
            ) : (
              <i className="bi bi-chevron-up fw-bold"></i>
            )}
          </button>
        </div>
      </div>
      {show && (
        <div className="app-share-container">
          <div className="app-share-title">
            Comparte tu código con tus amigos y acumula más puntos con cada
            compra que realicen.
          </div>
          <div className="app-share-qr">
            <QRCode
              size={256}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
                borderRadius: "4px",
              }}
              viewBox={`0 0 256 256`}
              value={code}
            />
          </div>
          <div className="app-share-qr-code">{code}</div>
          <div className="app-share-message">
            Comparte el código con tus amigos
          </div>
          <div className="app-share-actions">
            <div className="col-4 col-md-4">
              <a
                href={`https://wa.me/?text=Código de descuento: ${code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={IconWhatsApp}
                  alt="whatsapp"
                  width="36px"
                  height="36px"
                />
                <span>Whatsapp</span>
              </a>
            </div>
            <div className="col-4 col-md-4">
              <a
                href={`http://m.me/PAGE_NAME?ref=summer_coupon`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={IconMesseger}
                  alt="whatsapp"
                  width="36px"
                  height="36px"
                />
                <span>Facebook Messenger</span>
              </a>
            </div>
            <div className="col-4 col-md-4">
              <a
                href={`https://mail.google.com/mail/u/0/#search/rfc822msgid%3AC%C3%B3digo%20de%20descuento%3A${code}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={IconGmail}
                  alt="whatsapp"
                  width="36px"
                  height="36px"
                />
                <span>Gmail</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareComponent;
