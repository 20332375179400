import React, { useState } from "react";
import Wallet from "../../../assets/icons/wallet.svg";
import Qr from "../../../assets/icons/qr.svg";
import RowBack from "../../../assets/icons/row-back.svg";
import QRCode from "react-qr-code";
import "./Card.css";

const Card = ({
  count,
  title,
  code,
  handleGoToWallet = null,
  isHidden = false,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`app-card ${isFlipped ? "flipped" : ""}`}>
      <div className="app-card-front">
        <div className="app-card-img" />
        <div className="app-card-btn" onClick={flipCard}>
          <img src={Qr} alt="qr" width="12px" height="12px" />
        </div>
        <div className="app-card-content">
          <div className="app-card-count">{count}</div>
          <div className="app-card-bar"></div>
          <div className="app-card-title">{title}</div>
        </div>
        <div className="app-card-icon">
          <img src={Wallet} alt="wallet" width="10.32px" height="9.75px" />
        </div>
        {isHidden && (
          <div className="app-card-footer-title" onClick={handleGoToWallet}>
            Pulsa para acceder a tu wallet
          </div>
        )}
      </div>
      <div className="app-card-back">
        <div className="app-card-btn bg-dark" onClick={flipCard}>
          <img src={RowBack} alt="qr" width="12px" height="12px" />
        </div>
        <div className="app-card-qr">
          <div className="col-md-6 col-6 app-card-qr-code">
            <QRCode
              size={128}
              style={{
                height: "auto",
                width: "100%",
                maxWidth: "100%",
                borderRadius: "4px",
                border: "hidden",
              }}
              value={code}
              viewBox={`0 0 128 128`}
            />
          </div>
          <div className="col-md-6 col-6 app-card-qr-text">
            <span>{code}</span>
            <span>Código para tienda en linea</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
