import React, { useEffect, useState } from "react";
import OnboardingComponent from "../../components/onboarding/OnboardingComponent";
import HeaderLogo from "../../assets/images/onboarding_header.webp";
import { NavLink, Outlet } from "react-router-dom";
import "./Login.css";
import { postAuthenticate, postSearchProducts } from "../../../services/Services";
const Login = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {

    const data = {
      username: process.env.REACT_APP_ODOO_USERNAME,
      password: process.env.REACT_APP_ODOO_PASSWORD,
    };

    postAuthenticate(data)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    postSearchProducts({ ...data, uid: 77, args: [[]], filters: {} })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    // Authenticate(data)
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

  }, []);

  return show ? (
    <OnboardingComponent setShow={setShow} />
  ) : (
    <div className="app-login">
      <div className="app-login-header">
        <img
          className="img img-fluid"
          src={HeaderLogo}
          alt="header"
          width="100%"
          height="auto"
        />
      </div>
      <div className="app-login-container">
        <div className="app-login-shadow"></div>
      </div>
      <div className="app-login-body">
        <div className="app-login-options">
          <div className="col">
            <NavLink className="nav-link" to="/">
              Iniciar sesión
            </NavLink>
          </div>
          <div className="col vdivider">
            <span></span>
          </div>
          <div className="col">
            <NavLink className="nav-link" to="signup">
              Registrarse
            </NavLink>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Login;
