import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import AboutComponent from "../../components/about/AboutComponent";
import "./About.css";

const About = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-about">
      <div className="app-about-header">
        <span className="app-about-header-icon" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </span>
        <span className="app-about-header-label">SOBRE TUCANÊ</span>
      </div>
      <div className="app-about-container">
        <AboutComponent />
      </div>
    </div>
  );
};

export default About;
