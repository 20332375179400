export const search = ({
  db,
  uid,
  password,
  model,
  args,
  filters,
}) => `<methodCall>
    <methodName>execute_kw</methodName>
    <params>
        <param>
            <value>
                <string>${db}</string>
            </value>
        </param>
        <param>
            <value>
                <string>${uid}</string>
            </value>
        </param>
        <param>
            <value>
                <string>${password}</string>
            </value>
        </param>
        <param>
            <value>
                <string>${model}</string>
            </value>
        </param>
        <param>
            <value>
                <string>search</string>
            </value>
        </param>
        <param>
           ${generateXmlStructure(args)}
        </param>
        <param>
           ${generateXmlStructure(filters)}
        </param>
    </params>
</methodCall>`;

function generateXmlStructure(obj) {
  if ((typeof obj === "string") | (typeof object === "number")) {
    // If the object is a string, return it as an XML text node
    const type = typeof obj === "number" ? "int" : "string";
    return `<value><${type}>${obj}</${type}></value>`;
  }

  if (Array.isArray(obj)) {
    // If the object is an array, construct an XML array
    return `<array><data>${obj.map(generateXmlStructure).join("")}</data></array>`;
  }

  if (typeof obj === "object") {
    // If the object is an object, construct an XML struct
    const structEntries = Object.entries(obj).map(
      ([name, value]) =>
        `<member><name>${name}</name>${generateXmlStructure(value)}</member>`
    );

    return `<struct>${structEntries.join("")}</struct>`;
  }

  throw new Error("Unsupported data type for XML generation");
}
