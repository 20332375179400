import React from "react";
import Tucane from "../../assets/icons/tucane-dark.svg";
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Youtube from "../../assets/icons/youtube.svg";
import Pinterest from "../../assets/icons/pinterest.svg";
import TikTok from "../../assets/icons/tiktok.svg";
import Linkedln from "../../assets/icons/linkedln.svg";

const AboutComponent = () => {
  return (
    <div>
      <div className="app-about-container-icon">
        <img src={Tucane} alt="logo" width="29px" height="16px" />
      </div>
      <div className="app-about-paragraph">
        Buscamos destacar a través del vestido lo más auténtico de las personas.
        Por tal motivo, nuestros productos son atemporales y relajados. Nuestro
        estilo siempre estará de moda.
      </div>
      <div className="app-about-paragraph">
        El compromiso de Tucanê es mejorar el performance de tu vestido diario,
        a través de la innovación tecnológica, empleando materiales y procesos
        que certifican buenas prácticas en cuidado ambiental.
      </div>
      <div className="app-about-paragraph">
        Creamos colecciones de vestido integradas por productos que serán
        capaces de combinar perfectamente entre ellos, lo único que importará es
        cual te guste más o cuales vayan más con tu personalidad, lo demás lo
        solucionamos nosotros.
      </div>
      <div className="app-about-social-title">SÍGUENOS EN REDES</div>
      <div className="app-about-social-items">
        <div className="app-about-social-item">
          <a href="https://www.facebook.com/tucaneoficial/">
            <img src={Facebook} alt="facebook" width="36px" height="36px" />
          </a>
        </div>
        <div className="app-about-social-item">
          <a href="https://www.facebook.com/tucaneoficial/">
            <img src={Instagram} alt="facebook" width="36px" height="36px" />
          </a>
        </div>
        <div className="app-about-social-item">
          <a href="https://www.facebook.com/tucaneoficial/">
            <img src={Youtube} alt="facebook" width="36px" height="36px" />
          </a>
        </div>
        <div className="app-about-social-item">
          <a href="https://www.facebook.com/tucaneoficial/">
            <img src={Pinterest} alt="facebook" width="36px" height="36px" />
          </a>
        </div>
        <div className="app-about-social-item">
          <a href="https://www.facebook.com/tucaneoficial/">
            <img src={TikTok} alt="facebook" width="36px" height="36px" />
          </a>
        </div>
        <div className="app-about-social-item">
          <a href="https://www.facebook.com/tucaneoficial/">
            <img src={Linkedln} alt="facebook" width="36px" height="36px" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
