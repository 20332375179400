import React from "react";
import CardsComponent from "../../components/home/card/CardsComponent";
import BenefitsComponent from "../../components/home/benefits/BenefitsComponent";
import CarouselComponent from "../../components/home/carousel/CarouselComponent";
import GifCardComponent from "../../components/home/gifcard/GifCardComponent";
import ShareComponent from "../../components/home/share/ShareComponent";
import StoreComponent from "../../components/home/store/StoreComponent";
import "./Home.css";

const Home = (props) => {
  return (
    <div>
      <div className="app-home-welcome">¡Hola, José Antonio!</div>
      <div className="app-home-cards">
        <CardsComponent />
      </div>
      <div className="app-home-carousel">
        <CarouselComponent />
      </div>
      <div className="app-home-store-title">TIENDA</div>
      <div className="app-home-store">
        <StoreComponent />
      </div>
      <div className="app-home-benefits">
        <BenefitsComponent />
      </div>
      <div className="app-home-share">
        <ShareComponent />
      </div>
      <div className="app-home-gifcard">
        <GifCardComponent />
      </div>
    </div>
  );
};

export default Home;
