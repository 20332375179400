import React from "react";
import Tucane from "../../assets/icons/tucane-dark.svg";

const TermsComponent = () => {
  return (
    <div>
      <div className="app-about-container-icon">
        <img src={Tucane} alt="logo" width="29px" height="16px" />
      </div>
      <div className="app-terms-paragraph-title">
        CONDICIONES GENERALES DE CONTRATACIÓN
      </div>
      <div className="app-about-paragraph">
        Lea detenidamente las siguientes Condiciones de uso y Renuncia antes de
        utilizar el sitio Web Tucane.com. Al utilizar el sitio Web, manifiesta
        su conformidad con las condiciones de uso. Si no está de acuerdo con las
        condiciones de uso, no debería utilizar este sitio. Tucane.com se
        reserva el derecho a modificar, alterar o actualizar estas condiciones
        de uso en cualquier momento y Usted acepta estar obligado por dichas
        modificaciones, alteraciones o actualizaciones.
      </div>
      <div className="app-terms-paragraph-title">
        TÉRMINOS Y CONDICIONES DE COMPRA
      </div>
      <div className="app-about-paragraph">
        Por la utilización del Portal y/o la adquisición de cualquiera de los
        productos del mismo, suponen la aceptación como Usuario, sin reservas de
        ninguna clase, a todas y cada una de las Condiciones Generales de
        Contratación, de las Condiciones Generales de Utilización del Portal así
        como en su caso, a las Condiciones Particulares que, en su caso rijan la
        adquisición.
      </div>
    </div>
  );
};

export default TermsComponent;
