import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import app from "../../../config/app";

const RegisterComponent = () => {
  const auth = getAuth(app);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: "", password: "", password_confirmation: "" },
    validationSchema: yup.object({
      email: yup.string("Correo electrónico").email("El correo es requerido."),
      password: yup
        .string("Contraseña")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "8 caracteres - 1 símbolo - 1 mayúscula"
        ),
      password_confirmation: yup.string().equals([yup.ref("password")]),
    }),
    onSubmit: (values) => {
      createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredentials) => {
          const user = userCredentials.user;
          console.log(user);
          navigate("/verification-email");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });
    },
  });

  console.log(formik.errors);

  return (
    <form style={{ margin: "auto 5%" }} onSubmit={formik.handleSubmit}>
      <div className="form-floating mb-2">
        <input
          type="email"
          className={`form-control${formik.errors.email ? " is-invalid" : ""}`}
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Ingresa tu correo electrónico"
          autoComplete="off"
          required
        />
        <label htmlFor="email">Ingresa tu correo electrónico</label>
      </div>
      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password ? " is-invalid" : ""
          }`}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Contraseña"
          autoComplete="off"
          required
        />
        <label htmlFor="password">Contraseña</label>
      </div>
      <div className="app-login-form-info mb-2">
        <span> 8 caracteres - 1 símbolo - 1 mayúscula</span>
      </div>
      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password_confirmation ? " is-invalid" : ""
          }`}
          id="password_confirmation"
          name="password_confirmation"
          value={formik.values.password_confirmation}
          onChange={formik.handleChange}
          placeholder="Confirma tu contraseña"
          autoComplete="off"
          required
        />
        <label htmlFor="password">Confirma tu contraseña</label>
      </div>
      <div className="row m-auto">
        <button type="submit" className="app-form-button">
          Registrarse
        </button>
      </div>
    </form>
  );
};

export default RegisterComponent;
