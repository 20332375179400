import React, { useEffect, useState } from "react";
import db from "../../database/db";
import "./Achievements.css";
const Achievements = () => {
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    setAchievements(db.achievements);
  }, []);

  return (
    <div className="app-achievements">
      <div className="app-achievements-title">Logros</div>
      <div className="app-achievements-level">
        <div className="app-achievements-level-title">Nivel 1</div>
        <div className="app-achievements-level-bar">
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="app-achievements-list">
        {achievements.map((item, index) => (
          <div key={index} className="app-achievements-item">
            <img src={item.imagen} alt={`badge ${item.id}`} />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Achievements;
