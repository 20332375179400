import React from "react";
import { useNavigate } from "react-router-dom";
import HomeHeader from "../../../assets/images/home_header.webp";
import ChevronLeft from "../../../assets/icons/chevron-left.svg";
import ForgetPasswordComponent from "../../../components/auth/forget-password/ForgetPasswordComponent";
import "./ForgetPassword.css";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-forget">
      <div className="app-forget-header">
        <span className="app-forget-header-icon" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </span>
        <span className="app-forget-header-image">
          <img src={HomeHeader} alt="logo" width="100%" height="auto" />
        </span>
      </div>
      <div className="app-forget-container">
        <ForgetPasswordComponent />
      </div>
    </div>
  );
};

export default ForgetPassword;
