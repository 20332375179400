import React from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../../assets/icons/chevron-left.svg";
import ProfileComponent from "../../../components/auth/profile/ProfileComponent";
import "./Profile.css";

const Profile = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="app-profile">
      <div className="app-profile-header">
        <span className="app-profile-header-icon" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </span>
        <span className="app-profile-header-label">PERFIL</span>
      </div>
      <div className="app-profile-container">
        <ProfileComponent />
      </div>
    </div>
  );
};

export default Profile;
