import React, { useState } from "react";
import HeaderLogo from "./assets/images/logo.webp";
import "./App.css";
import { Outlet } from "react-router-dom";
import FooterComponent from "./components/footer/FooterComponent";
import MenuComponent from "./components/menu/MenuComponent";
import DialogComponent from "./components/dialog/DialogComponent";
import ActionsContext from "./context/ActionsContext";
import Localization from "./assets/icons/localization.svg";
import Menu from "./assets/icons/menu-burger.svg";


function App() {
  const [showMenu, setShowMenu] = useState(false);

  const handleDialog = (children = null) => {
    setDialog((dialog) => ({
      show: !dialog.show,
      children: children ? children : dialog.children,
      handleDialog: handleDialog,
    }));
  };

  const [dialog, setDialog] = useState({
    show: false,
    children: null,
    handleDialog: handleDialog,
  });

  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <ActionsContext.Provider value={{ dialog }}>
      <div className="app">
        <div className="app-header">
          <div className="col-9">
            <img
              className="img img-fluid"
              src={HeaderLogo}
              alt="HeaderLogo"
              width="77px"
              height="23px"
            />
          </div>
          <div className="col-1 icon">
            <img
              src={Localization}
              alt="localization"
              width="24.95px"
              height="24px"
            />
          </div>
          <div className="col-1 icon" onClick={handleMenu}>
            <img src={Menu} alt="localization" width="17px" height="24px" />
          </div>
        </div>
        <div className="app-container">
          <Outlet />
        </div>
        <div className="app-footer">
          <FooterComponent />
        </div>
      </div>
      {showMenu && (
        <div className="app-menu">
          <MenuComponent handleMenu={handleMenu} />
        </div>
      )}
      {dialog.show && (
        <div className="app-dialog">
          <DialogComponent />
        </div>
      )}
    </ActionsContext.Provider>
  );
}

export default App;
