import React from "react";

const ChangePassword = () => {
  return (
    <div>
      <div className="app-form-title">CAMBIAR CONTRASEÑA</div>
      <div className="app-form-floating">
        <label className="app-form-label" htmlFor="current_password">
          <input
            className="app-form-control"
            type="text"
            name="current_password"
            id="current_password"
            placeholder="Contraseña actual"
            //   value={formik.values.correo}
            //   onChange={formik.handleChange}
            autoComplete="off"
          />
          <span>Contraseña actual</span>
        </label>
      </div>
      <div className="app-form-divider"><span></span></div>
      <div className="app-form-floating">
        <label className="app-form-label" htmlFor="password">
          <input
            className="app-form-control"
            type="text"
            name="password"
            id="password"
            placeholder="Nueva contraseña"
            //   value={formik.values.correo}
            //   onChange={formik.handleChange}
            autoComplete="off"
          />
          <span>Nueva contraseña</span>
        </label>
      </div>
      <div className="app-form-floating">
        <label className="app-form-label" htmlFor="password_confirm">
          <input
            className="app-form-control"
            type="text"
            name="password_confirm"
            id="password_confirm"
            placeholder="Confirmar contraseña"
            //   value={formik.values.correo}
            //   onChange={formik.handleChange}
            autoComplete="off"
          />
          <span>Confirmar contraseña</span>
        </label>
      </div>
      <div className="app-form-btn mt-5 mb-3">
          <button type="button">Cambiar Contraseña</button>
        </div>
    </div>
  );
};

export default ChangePassword;
