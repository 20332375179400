import { useFormik } from "formik";
import * as yup from "yup";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import { getAuth } from "firebase/auth";
// import app from "../../../config/app";

const LoginComponent = () => {
  const navigate = useNavigate();
  // const auth = getAuth(app);
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: yup.object({
      email: yup.string("Correo electrónico").email("El correo es requerido."),
      password: yup
        .string("Contraseña")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "8 caracteres - 1 símbolo - 1 mayúscula"
        ),
    }),
    onSubmit: (values) => {
      navigate("/home");
      // signInWithEmailAndPassword(auth, values.email, values.password)
      //   .then((userCredential) => {
      //     // Signed in
      //     const user = userCredential.user;
      //     navigate("/home");
      //     // ...
      //   })
      //   .catch((error) => {
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     console.log(errorCode, errorMessage);
      //   });
    },
  });

  return (
    <form style={{ margin: "auto 5%" }} onSubmit={formik.handleSubmit}>
      <div className="form-floating mb-2">
        <input
          type="email"
          className={`form-control${formik.errors.email ? " is-invalid" : ""}`}
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder="Ingresa tu correo electrónico"
          autoComplete="off"
        />
        <label htmlFor="email">Ingresa tu correo electrónico</label>
      </div>
      <div className="form-floating mb-2">
        <input
          type="password"
          className={`form-control${
            formik.errors.password ? " is-invalid" : ""
          }`}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder="Contraseña"
          aria-errormessage={formik.errors.password}
          autoComplete="off"
        />
        <label htmlFor="password">Contraseña</label>
      </div>
      <div className="app-login-form-info">
        <span> 8 caracteres - 1 símbolo - 1 mayúscula</span>
      </div>
      <div className="row m-auto">
        <button type="submit" className="app-form-button">
          Entrar
        </button>
      </div>
      <div className="app-form-forget-password">
        <NavLink className="nav-link" to="/forget-password">
          ¿Olvidaste tu contraseña?
        </NavLink>
      </div>
    </form>
  );
};

export default LoginComponent;
