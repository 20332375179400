import React, { useState } from "react";
import QrScanner from "qr-scanner";
import "./Scanner.css";
import { useEffect } from "react";

const Scanner = () => {
  const [data, setData] = useState("");
  const handleScanner = () => {
    const video = document.querySelector(".app-video");
    const scanner = new QrScanner(video, (result) => setResult(result), {
      onDecodeError: (error) => {
        console.log(error);
      },
      highlightScanRegion: true,
      highlightCodeOutline: true,
    });

    scanner.hasFlash().then((hasFlash) => {
      console.log(hasFlash);
    });

    scanner.start().then(() => {
      scanner.hasFlash().then((hasFlash) => {
        console.log(hasFlash);
      });
      // List cameras after the scanner started to avoid listCamera's stream and the scanner's stream being requested
      // at the same time which can result in listCamera's unconstrained stream also being offered to the scanner.
      // Note that we can also start the scanner after listCameras, we just have it this way around in the demo to
      // start the scanner earlier.
      QrScanner.listCameras(true).then((cameras) =>
        cameras.forEach((camera) => {
          const option = document.createElement("option");
          option.value = camera.id;
          option.text = camera.label;
          console.log(camera);
        })
      );
    });
  };

  const setResult = (value) => {
    setData(value?.data);
  };

  QrScanner.hasCamera().then((hasCamera) => console.log(hasCamera));

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {};
  }, []);

  return (
    <div className="app-scanner">
      <div className="app-scanner-result">
        <a href={`${data}`}>{data}</a>
      </div>
      <video className="app-video"></video>
      <div className="app-scanner-actions">
        <button className="app-scanner-btn" onClick={handleScanner}>
          scannear
        </button>
      </div>
    </div>
  );
};
export default Scanner;
