import React from "react";
import FacebookComponent from "./FacebookComponent";
import GoogleComponent from "./GoogleComponent";
import { NavLink } from "react-router-dom";

const MenuComponent = () => {

  return (
    <div className="app-menu-component">
      <div className="app-menu-component-item">
        <GoogleComponent />
      </div>
      <div className="app-menu-component-item">
        <FacebookComponent />
      </div>
      <div className="app-menu-component-item">
        <button type="button" className="app-login-button">
          <NavLink className="nav-link" to="login">
            Continuar con correo
          </NavLink>
        </button>
      </div>
    </div>
  );

};

export default MenuComponent;
