import React from "react";
import { useNavigate } from "react-router-dom";
import GifCard from "../../../assets/images/gif-card.webp";
import "./GifCard.css";

const GifCardComponent = () => {
  const navigate = useNavigate();

  const handleGoToGiftCards = () => {
    navigate("gifcards");
  };

  return (
    <div className="app-gifcard" onClick={handleGoToGiftCards}>
      <span className="app-gifcard-title">GIFT CARDS</span>
      <img src={GifCard} alt="gif card" height="100%" width="100%" />
    </div>
  );
};

export default GifCardComponent;
