import React, { useEffect, useState } from "react";
import db from "../../database/db";

const PromotionsComponent = () => {
  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    setPromotions(db.promotions);

    return () => {};
  }, []);

  return (
    <div className="app-promotions-list">
      {promotions.map((item, index) => (
        <div key={index} className="app-promotions-item">
          <img
            src={item.imagen}
            alt={`promotions ${index}`}
            width="100%"
            height="auto"
          />
          <span className="app-promotions-item-title">{item.title}</span>
          <div className="app-promotions-item-discount">
            <span className="discount">{item.discount}</span>
            <span className="divider"></span>
            <span className="subtitle">{item.subtitle}</span>
          </div>
          <span className="app-promotions-item-bar"></span>
          <span className="app-promotions-item-description">
            {item.description}
          </span>
        </div>
      ))}
    </div>
  );
};

export default PromotionsComponent;
