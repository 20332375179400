import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../assets/icons/chevron-left.svg";
import db from "../../database/db";

const ListGiftCardsComponent = (props) => {
  const navigate = useNavigate();
  const [giftCards, setGiftCards] = useState([]);

  useEffect(() => {
    setGiftCards(db.gifcards);
    return () => {};
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const handleGoToPersonalize = (value) => {
    navigate(`${value}`);
  };

  return (
    <div className="app-giftcards">
      <div className="row app-giftcards-header">
        <div className="col-2 col-md-2" onClick={handleBack}>
          <img
            src={ChevronLeft}
            alt={"chevron left"}
            width="16px"
            height="16px"
          />
        </div>
        <div className="col-10 col-md-10">GIFT CARDS</div>
      </div>
      <div className="app-giftcards-container">
        <div className="app-giftcards-list">
          {giftCards.map((item, index) => (
            <div
              key={index}
              className="app-giftcards-item"
              onClick={() => handleGoToPersonalize(item.id)}
            >
              <img
                src={item.imagen}
                alt={`giftcards ${index}`}
                width="auto"
                height="auto"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ListGiftCardsComponent;
